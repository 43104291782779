@font-face {
  font-family: 'Metropolis';
  font-weight: 100;
  font-style: normal;
  font-display: swap;
  src: local('Metropolis Thin'), local('Metropolis Thin'),
    url('./Metropolis/Metropolis-Thin.woff2') format('woff2');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 100;
  font-style: italic;
  font-display: swap;
  src: local('Metropolis Thin Italic'), local('Metropolis Thin Italic'),
    url('./Metropolis/Metropolis-ThinItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 200;
  font-style: normal;
  font-display: swap;
  src: local('Metropolis Extra Light'), local('Metropolis Extra Light'),
    url('./Metropolis/Metropolis-ExtraLight.woff2') format('woff2');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 200;
  font-style: italic;
  font-display: swap;
  src: local('Metropolis Extra Light Italic'), local('Metropolis Extra Light Italic'),
    url('./Metropolis/Metropolis-ExtraLightItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: local('Metropolis Light'), local('Metropolis Light'),
    url('./Metropolis/Metropolis-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: local('Metropolis Light Italic'), local('Metropolis Light Italic'),
    url('./Metropolis/Metropolis-LightItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local('Metropolis Regular'), local('Metropolis Regular'),
    url('./Metropolis/Metropolis-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: local('Metropolis Regular Italic'), local('Metropolis Regular Italic'),
    url('./Metropolis/Metropolis-RegularItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: local('Metropolis Medium'), local('Metropolis Medium'),
    url('./Metropolis/Metropolis-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src: local('Metropolis Medium Italic'), local('Metropolis Medium Italic'),
    url('./Metropolis/Metropolis-MediumItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: local('Metropolis Semi Bold'), local('Metropolis Semi Bold'),
    url('./Metropolis/Metropolis-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 600;
  font-style: italic;
  font-display: swap;
  src: local('Metropolis Semi Bold Italic'), local('Metropolis Semi Bold Italic'),
    url('./Metropolis/Metropolis-SemiBoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: local('Metropolis Bold'), local('Metropolis Bold'),
    url('./Metropolis/Metropolis-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: local('Metropolis Bold Italic'), local('Metropolis Bold Italic'),
    url('./Metropolis/Metropolis-BoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src: local('Metropolis Extra Bold'), local('Metropolis Extra Bold'),
    url('./Metropolis/Metropolis-ExtraBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 800;
  font-style: italic;
  font-display: swap;
  src: local('Metropolis Extra Bold Italic'), local('Metropolis Extra Bold Italic'),
    url('./Metropolis/Metropolis-ExtraBoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: local('Metropolis Black'), local('Metropolis Black'),
    url('./Metropolis/Metropolis-Black.woff2') format('woff2');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 900;
  font-style: italic;
  font-display: swap;
  src: local('Metropolis Black Italic'), local('Metropolis Black Italic'),
    url('./Metropolis/Metropolis-BlackItalic.woff2') format('woff2');
}
