.react-pdf__Document {
  width: 100%;
  height: 100%;
}

.react-pdf__Page {
  width: 100%;
  height: 100%;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}
